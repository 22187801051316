@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Saira+Stencil+One&display=swap');

:root{
  /* COLOR */
    --red_color       : #BE1620 !important;
    --dark_red_color  : darkred !important;
    --light_red_color : #FFC4C4 !important;
    --black_color     : black !important;
    --grey_color      : #646464 !important;
    --light_grey_color: #F4F4F4 !important;
    --white_color     : white !important;
    

  /* FONT-SIZE */
  --font_size_title_page  : clamp(1.25rem, calc(1.07rem + 0.78vw), 2rem);
  --font_size_header_page : clamp(1rem, calc(0.88rem + 0.52vw), 1.5rem);
  --font_size_normal      : clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem); 
  /* --font_size_normal      : clamp(0.63rem, calc(0.47rem + 0.65vw), 1.25rem); */
  --font_size_label       : clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem); 
  --font_size_input       : clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem); 
  --font_size_error       : clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem); 
  --font_size_popup       : clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
  --font_size_header_popup: clamp(1rem, calc(0.76rem + 1.04vw), 2rem);


  /* ACTIVE FONTS */
  --font_family       : "Poppins", sans-serif !important;
  --font_family-title : 'Saira Stencil One', sans-serif !important;
  /* REGULAR */
  --font_weight_regular: 400;
  /* SEMI-BOLD */
  --font_weight_semibold: 600;
  /* BOLD */
  --font_weigth_bold: 700;
}

/* DEFAULT SETTINGS */

.DefaultTitlePage{
  font-size: var(--font_size_title_page);  
  font-family: var(--font_family-title);
  color: var(--red_color) ;
  margin-bottom: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem);
  text-align: center ;
}

.DefaultTitleHomePage{
  font-size: var(--font_size_title_page);  
  /* font-weight: var(--font_weigth_bold); */
  font-family: var(--font_family-title);
  color: var(--red_color) ;
  text-align: center ;
}

.DefaultHeaderPage{
  color: var(--red_color);  
  font-family: var(--font_family);
  font-weight: var(--font_weigth_bold);
  font-size: var(--font_size_header_page);
}

.DefaultNormalText{
  color: var(--black_color);
  font-size: var(--font_size_normal);
  font-family:var(--font_family);
  font-weight: var(--font_weight_regular);
}


.App {
  display:grid;
  grid-template-rows:auto 1fr auto;
  grid-template-columns:100%;
  margin-bottom: clamp(3.28rem, calc(2.86rem + 1.78vw), 5rem);
  /* min-height:100vh; */
  /* min-height:100svh; */
}

.loadRoleSpinner{
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.App .logincontainer {
  left: 60rem;
}

.LoginDropDown{
  margin-right: clamp(0rem, calc(-1.67rem + 7.12vw), 6.88rem) !important;
}


.navbar{
  background-color: #BE1620;
  font-family: var(--font_family);
  font-size: 1.25rem;
  height: clamp(3.25rem, calc(2.83rem + 1.81vw), 5rem) !important;
  /* max-height: 4.17vh; */
}

.NavbarLeftResponsive{
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.ArrowVector{
  margin-left:  clamp(0.31rem, calc(0.24rem + 0.32vw), 0.63rem) !important;
  width:  clamp(0.75rem, calc(0.63rem + 0.52vw), 1.25rem) !important;
  height:  clamp(0.75rem, calc(0.63rem + 0.52vw), 1.25rem)  !important;
}

.NavbarUserName{
  overflow: hidden;
}

.NavBrand{
  width: clamp(11.13rem, calc(9.56rem + 6.67vw), 17.56rem);
  background-color: #fff;
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoKiri{
  /* position: relative;
  right: 50%; */
  height: clamp(2.13rem, calc(1.82rem + 1.29vw), 3.38rem);
  /* height: clamp(3.25rem, calc(2.83rem + 1.81vw), 5rem) !important; */
  width: clamp(5.06rem, calc(4.32rem + 3.17vw), 8.13rem);
}

.LoginModalContent{
  font-family: var(--font_family);
  width: clamp(21.69rem, calc(19.67rem + 8.61vw), 30rem);
}

/* .nav-link{
  padding: 0.625rem !important;
} */

.nav-link, .LogoutDropDownBtn{
  font-family: var(--font_family) !important;
  font-weight: var(--font_weight_semibold) !important;
  font-size: clamp(0.88rem, calc(0.78rem + 0.39vw), 1.25rem) !important;
}

.PopUpLogout {
  /* position: fixed; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.PopUpLogin {
  /* z-index: 1000000000; */
  position: absolute;
  top: 6rem;
  right: .025rem;
  /* left: 75rem; */
  /* transform: translate(-50%, -50%); */
}
.navbar .nav-link,
.navbar .nav-link.active,
.navbar .nav-link:focus,
.navbar .nav-link:hover,
.navbar .dropdown-menu a,
.navbar .dropdown-item,
.navbar .dropdown-item:hover,
.navbar .dropdown-item:focus{
 color: white !important;
}

.navbar .nav-link:hover {
  box-shadow:  0 0.125rem 0 white; 
}



.navbar .dropdown-menu {
  background-color: #BE1620; 
}

.NavLink{
  font-family: var(--font_family);
  font-weight: 600;
  width:  clamp(7.31rem, calc(6.58rem + 3.11vw), 10.31rem) !important;
}

.navbar .dropdown-item:hover {
  background-color: darkred; 
}


.HomeButton img{
  height: 100%;
}

.LoginLogo { 
  margin-left: clamp(0.31rem, calc(0.24rem + 0.32vw), 0.63rem);
}

.navbar-collapse.show .nav-link,
.navbar-collapse.collapsing .nav-link {
  padding-left: 0.75rem;
}

.navbar .nav-link.dropdown-toggle::after {
  display: none;
}

.dropright-nav-dropdown .dropdown-menu {
  right: auto !important;
  left: 100% !important;
}

.dropright-nav-dropdown .dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}
.PasswordSubContainer {
  display: flex;
  align-items: center;
}

.password-input-container {
  position: relative;
  flex: 1;
}

.eyeicon {
  position: absolute;
  right: 1.5rem;
  flex: end;
}

.PopUpLogoutButton{
  font-weight: var(--font_weight_semibold) !important;
  background-color: #BE1620 !important;
  border: none !important;
  border-radius: 0.625rem !important;
  width: clamp(9.56rem, calc(8.93rem + 2.72vw), 12.19rem) !important;
  height: clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem) !important;
}

.PopUpLogoutContent{
  font-family: var(--font_family);
  width: clamp(21.69rem, calc(19.67rem + 8.61vw), 30rem);
  margin-top: clamp(1.56rem, calc(1.3rem + 1.1vw), 2.63rem);
  margin-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
}

.CancelButton{
  background-color: transparent !important;
  color: #BE1620 !important;
  border: 1px solid !important;
}

.ForgotPasswordPopUp{
  font-family: var(--font_family);
  display: flex !important;
  justify-content: center;
}

.ForgotPasswordMessageContainer{
 display: flex;
 justify-content: center;
  width: 100%;
}

.ForgotPasswordMessage{
  font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
  margin-bottom: clamp(1.56rem, calc(1.3rem + 1.1vw), 2.63rem);
  font-weight: 400;
  width: 16.938rem;
  color: #BE1620;
  text-align: center;
}

.ForgotPasswordContainer{
  margin-top: clamp(1.25rem, calc(0.95rem + 1.29vw), 2.5rem);
  margin-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
  margin-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
  margin-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
}
.ForgotPasswordContent{
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
}

.ForgotPasswordTitle{
  font-weight: var(--font_weigth_bold) !important;
  font-size: clamp(1rem, calc(0.76rem + 1.04vw), 2rem) !important;
  color: #BE1620 !important;
}

.ForgotPasswordTopContainer{
  width: 100% ;
  height: clamp(2.75rem, calc(1.9rem + 3.62vw), 6.25rem) !important;
  display: flex !important;
  padding-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
  padding-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem) !important;
  padding-top: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem) !important;
  padding-bottom: clamp(0.63rem, calc(0.32rem + 1.29vw), 1.88rem) !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 1.25rem 0px 0px 0px !important;
  box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;
}

.ForgotPasswordFormGroup{
  margin-bottom: clamp(1.88rem, calc(1.72rem + 0.65vw), 2.5rem);
}

.PopUpLogoutHome{
  display: flex !important;
  flex-direction: row;
  justify-content: center!important;
  align-items: center;
}

.PopUpLogoutTopHome{
  width: 100% ;
  height: clamp(2.75rem, calc(1.9rem + 3.62vw), 6.25rem) !important;
  display: flex !important;
  justify-content: center!important;
  align-items: center;
  border-radius: 1.25rem 0px 0px 0px !important;
  box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;
}

.ForgotPasswordSuccessModal{
  font-family: var(--font_family);
  background-color: rgba(0,0,0,.5) !important;
}

.ForgotPasswordSucessTitle{
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: clamp(1rem, calc(0.76rem + 1.04vw), 2rem) !important;
  color: #BE1620 !important;
}

.Nav{
  /* background-color: #646464; */
  top: 0% !important;
  /* height: 100%; */
  width: 100%;
  position: fixed !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  z-index: 1000;
}

.ForgotPasswordSuccessTopContainer{
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 1.25rem 0px 0px 0px !important;
  box-shadow: 0px 0.125rem 0.563rem 0px rgba(0, 0, 0, 0.15) !important;
}
.ForgotPasswordSuccessContent{
  margin-bottom: clamp(1.25rem, calc(1.1rem + 0.65vw), 1.88rem);
  margin-left: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
  margin-right: clamp(0.88rem, calc(0.48rem + 1.68vw), 2.5rem);
  margin-top:clamp(1.56rem, calc(1.33rem + 0.97vw), 2.5rem);
}
.NotifInfo{
  font-size: clamp(0.88rem, calc(0.84rem + 0.13vw), 1rem);
  font-weight: 400;
  text-align: center;
}

.ForgotPasswordSuccessButton{
  margin-top:  clamp(1.56rem, calc(1.33rem + 0.97vw), 2.5rem) !important;
  width: clamp(19.94rem, calc(18.71rem + 5.24vw), 25rem) !important;
  height:  clamp(2.75rem, calc(2.36rem + 1.68vw), 4.38rem) !important;
}

.FooterForgotPassword{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.ModalVerifyLater{
  
  z-index: 2000 !important;
  background-color: rgba(0,0,0,.5) !important;
}

@media (max-width: 768px){ /* Mobile Responsive  */
  .DefaultTitlePage{
    text-align: left;
  }

  .DefaultTitleHomePage{
    text-align: left ;
  }

  .NavLinkRes{
    display: none !important;
  }
}